import { Link } from "gatsby";
import React from "react";

let flatContent = [];
const trio1 = [
    {
        nazev: "Byt 1",
        plocha: "132,15",
        zahrada: "390",
        cena: 8590000,
        url: "1/byt-1",
        stav: "prodáno",
    },
    {
        nazev: "Byt 2",
        plocha: "128,40",
        zahrada: "230",
        cena: 8990000,
        url: "1/byt-2",
        stav: "prodáno",
    },
    {
        nazev: "Byt 3",
        plocha: "107,69",
        zahrada: "250",
        cena: 7490000,
        url: "1/byt-3",
        stav: "prodáno",
    },
];
const trio2 = [
    {
        nazev: "Byt 1",
        plocha: "132,15",
        zahrada: "330",
        cena: 8960000,
        url: "2/byt-1",
        stav: "prodáno",
    },
    {
        nazev: "Byt 2",
        plocha: "128,40",
        zahrada: "340",
        cena: 9390000,
        url: "2/byt-2",
        stav: "prodáno",
    },
    {
        nazev: "Byt 3",
        plocha: "107,69",
        zahrada: "220",
        cena: 8580000,
        url: "2/byt-3",
        stav: "prodáno",
    },
];
const trio3 = [
    {
        nazev: "Byt 1",
        plocha: "132,15",
        zahrada: "320",
        cena: 8960000,
        url: "3/byt-1",
        stav: "prodáno",
    },
    {
        nazev: "Byt 2",
        plocha: "128,40",
        zahrada: "270",
        cena: 8790000,
        url: "3/byt-2",
        stav: "prodáno",
    },
    {
        nazev: "Byt 3",
        plocha: "107,69",
        zahrada: "200",
        cena: 8580000,
        url: "3/byt-3",
        stav: "prodáno",
    },
];

export const Flats = ({ trio, flat }) => {
    switch (trio) {
        case 1:
            flatContent = trio1;
            break;
        case 2:
            flatContent = trio2;
            break;
        case 3:
            flatContent = trio3;
            break;
        default:
            flatContent = trio1;
    }

    switch (flat) {
        case 1:
            flatContent = [flatContent[0]];
            break;
        case 2:
            flatContent = [flatContent[1]];
            break;
        case 3:
            flatContent = [flatContent[2]];
            break;
        default:
            flatContent = flatContent;
    }

    return (
        <>
            <div className="grid ">
                <div>
                    <div className="grid grid-cols-3 lg:grid-cols-6 lg:space-x-4 lg:gap-2  text-xs ">
                        <div className="p-2">Dům</div>
                        <div className="p-2">Byt</div>
                        <div className="p-2 text-right">Užitná plocha</div>
                        <div className="p-2 text-right">Plocha zahrady</div>
                        <div className="p-2 lg:text-right">Cena</div>
                        <div className="p-2 lg:text-right">Stav</div>
                    </div>
                    <div className="bg-gray-100 text-sm divide-y divide-gray-300 border border-gray-300 h-auto ">
                        {flatContent.map((flatData, flatIdx) =>
                            flatData.stav === "volný" ? (
                                <Link
                                    to={"/planky/" + flatData.url}
                                    key={flatIdx}
                                    className={
                                        "grid grid-cols-3 lg:grid-cols-6 space-x-4 gap-2 hover:bg-green-200 bg-green-500 bg-opacity-20 text-green-700 font-bold  "
                                    }
                                >
                                    <div className="p-2">Trio {trio}</div>
                                    <div className="p-2">{flatData.nazev}</div>
                                    <div className="p-2 text-right">
                                        {flatData.plocha} m<sup>2</sup>
                                    </div>
                                    <div className="p-2 text-right">
                                        {flatData.zahrada} m<sup>2</sup>
                                    </div>
                                    <div className="p-2 text-right">
                                        {flatData.cena.toLocaleString()} Kč
                                    </div>
                                    <div className="p-2 text-right">
                                        {flatData.stav}
                                    </div>
                                </Link>
                            ) : (
                                <div
                                    key={flatIdx}
                                    className={
                                        "grid grid-cols-3 lg:grid-cols-6 space-x-4 gap-2  " +
                                        (flatData.stav === "prodáno"
                                            ? "text-red-700 bg-red-100 opacity-60"
                                            : "text-blue-700 bg-blue-100")
                                    }
                                >
                                    <div className="p-2">Trio {trio}</div>
                                    <div className="p-2">{flatData.nazev}</div>
                                    <div className="p-2 text-right">
                                        {flatData.plocha} m<sup>2</sup>
                                    </div>
                                    <div className="p-2 text-right">
                                        {flatData.zahrada} m<sup>2</sup>
                                    </div>
                                    <div className="p-2 text-right">
                                        {flat ? (
                                            <>
                                                {flatData.cena.toLocaleString()}{" "}
                                                Kč
                                            </>
                                        ) : (
                                            <>-</>
                                        )}
                                    </div>
                                    <div className="p-2 text-right">
                                        {flatData.stav}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
