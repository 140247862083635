

import React from "react"
import Img01 from "../images/gallery-byt/galerie-byt01.jpg"
import Img02 from "../images/gallery-byt/galerie-byt02.jpg"
import Img03 from "../images/gallery-byt/galerie-byt03.jpg"
import Img04 from "../images/gallery-byt/galerie-byt04.jpg"
import Img05 from "../images/gallery-byt/galerie-byt05.jpg"
import Img06 from "../images/gallery-byt/galerie-byt06.jpg"
import Img07 from "../images/gallery-byt/galerie-byt07.jpg"
import Img08 from "../images/gallery-byt/galerie-byt08.jpg" 
import Img09 from "../images/gallery-byt/galerie-byt09.jpg" 
import Img10 from "../images/gallery-byt/galerie-byt10.jpg" 
import Img11 from "../images/gallery-byt/galerie-byt11.jpg" 
import Img12 from "../images/gallery-byt/galerie-byt12.jpg" 

import { SRLWrapper } from "simple-react-lightbox";
 
const files = [
  {name: 'Vizualizace bytu', url: Img01 },
  {name: 'Vizualizace bytu', url: Img02 },
  {name: 'Vizualizace bytu', url: Img03 },
  {name: 'Vizualizace bytu', url: Img04 },
  {name: 'Vizualizace bytu', url: Img05 },
  {name: 'Vizualizace bytu', url: Img06 },
  {name: 'Vizualizace bytu', url: Img07 },
  {name: 'Vizualizace bytu', url: Img08 },
  {name: 'Vizualizace bytu', url: Img09 },
  {name: 'Vizualizace bytu', url: Img10 },
  {name: 'Vizualizace bytu', url: Img11 },
  {name: 'Vizualizace bytu', url: Img12 },
]
 


export const FlatGallery = () => {
    return (
        <>
        <SRLWrapper>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          {files.map((file,index) => {
            return (
              <div className={"overflow-hidden shadow-md border hover:border-gray-500 "}>
                <img
                  className="cursor-pointer object-cover min-h-full "
                  src={file.url}
                  alt={file.name}
                />
              </div>
            )
          })}
      </div>
      </SRLWrapper>
        </>
    )
}